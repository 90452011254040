
import { defineComponent } from 'vue';
import Landing from "@/views/Landing.vue"

export default defineComponent({
  name: 'App',
  components: {
    Landing,
  }
});
