
import { defineComponent } from 'vue';
import anime from "animejs";

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface AnimationObj {seen: boolean, callback: null | ((el: HTMLElement) => void), el: null | HTMLElement, observer: null | IntersectionObserver}

export default defineComponent({
  name: 'Landing',
  mounted: function () {
    Object.keys(this.animations).forEach(key => {
        const obj = this.animations[key]
        obj.el = document.getElementById(key)        
    })
    this.enableHeaderContainerAnime()
    this.enableFooterDemoBtnAnime()
    this.enableBg3TextAnime()
    this.enableCycleText()
  },
  methods: {
    enableHeaderContainerAnime: function () {
         this.animations.header_container.observer = new IntersectionObserver((entries) => {      
            entries.forEach(async entry => {
                // you can find out easily if target is intersecting by inspecting `isIntersecting` property
                if (entry.isIntersecting && !this.animations.header_container.seen) {
                    this.animations.header_container.seen = true
                    anime({
                        targets: [this.animations.header_icon.el],
                        duration: 650,
                        opacity: 1,
                        easing: 'easeInExpo',
                    });
                    anime({
                        targets: [this.animations.header_text.el],
                        duration: 1500,
                        opacity: 1,
                        translateY: 100,
                        delay: 200,
                        easing: 'easeInOutExpo',
                    }); 
                    await sleep(1400)
                    anime({
                        targets: [this.animations.header_demobtn.el],
                        duration: 120,
                        height: 61,
                        easing: 'linear',
                    });
                }
            });
        }, {
            root: null,
            threshold: 1.0,
        })
        this.animations.header_container.observer.observe(this.animations.header_container.el as Element)
    },
    enableFooterDemoBtnAnime: function () {
         this.animations.footer_demobtn.observer = new IntersectionObserver((entries) => {      
            entries.forEach(entry => {
                // you can find out easily if target is intersecting by inspecting `isIntersecting` property
                if (entry.isIntersecting && !this.animations.footer_demobtn.seen) {
                    this.animations.footer_demobtn.seen = true
                    anime({
                        targets: [this.animations.footer_demobtn.el],
                        duration: 120,
                        height: 61,
                        delay: 500,
                        easing: 'linear',
                    });
                    
                }
            });
        }, {
            root: null,
            threshold: 1.0,
        })
        this.animations.footer_demobtn.observer.observe(this.animations.footer_demobtn.el as Element)
    },
    enableBg3TextAnime: function () {
         this.animations.bg3_text.observer = new IntersectionObserver((entries) => {      
            entries.forEach(entry => {
                // you can find out easily if target is intersecting by inspecting `isIntersecting` property
                if (entry.isIntersecting && !this.animations.bg3_text.seen) {
                    this.animations.bg3_text.seen = true
                    anime({
                        targets: [this.animations.bg3_text.el],
                        duration: 600,
                        opacity: 1,
                        easing: 'linear',
                    });
                    
                }
            });
        }, {
            root: null,
            threshold: 1.0,
        })
        this.animations.bg3_text.observer.observe(this.animations.bg3_text.el as Element)
    },
    enableCycleText: function () {
         this.animations.cycle_text.observer = new IntersectionObserver((entries) => {      
            entries.forEach(entry => {
                // you can find out easily if target is intersecting by inspecting `isIntersecting` property
                if (entry.isIntersecting && !this.animations.cycle_text.seen) {
                    this.animations.cycle_text.seen = true
                    this.startCycleText()
                }
            });
        }, {
            root: null,
            threshold: 1.0,
        })
        this.animations.cycle_text.observer.observe(this.animations.cycle_text_container.el as Element)
    },
    startCycleText: async function(){
        const el = this.animations.cycle_text.el
        if(!el) return
        console.log("start")
        await sleep(300)
        for (const text of this.text_options){
            this.cycleTextByString(el, text)
            
            await sleep(4400)
        }
        
    },
    cycleTextByString: function (el: HTMLElement, str: string){
        el.textContent = str
        anime({
            targets: [el],
            duration: 0,
            translateY: -30,
            complete: () => {
                anime({
                    targets: [el],
                    duration: 400,
                    translateY: 0,
                    easing: 'easeOutSine',
                    complete: () => {
                        if(str !== 'PEACE OF MIND')
                         anime({
                            targets: [el],
                            duration: 400,
                            translateY: 50,
                            delay: 3600,
                            easing: 'easeInSine',
                        });
                    }
                });
            }
        })
       
        
        
        
        
    },
    
  },
  unmounted: function (){
    Object.values(this.animations).forEach(animation => {
        if(animation.observer){
            animation.observer.disconnect()
        }
    })
  },
  data: function () {
    return {
        text_options: ["TIME", "ENERGY", "EXPERTISE", "PEACE OF MIND"],
        animations: {
            header_container: {seen: false, callback: null, el: null, observer: null},
            header_icon: {seen: false, callback: null, el: null, observer: null},
            cycle_text: {seen: false, callback: null, el: null, observer: null},
            cycle_text_container: {seen: false, callback: null, el: null, observer: null},
            header_text: {seen: false, callback: null, el: null, observer: null},
            header_demobtn: {seen: false, callback: null, el: null, observer: null},
            bg3_text: {seen: false, callback: null, el: null, observer: null},
            footer_demobtn: {seen: false, callback: null, el: null, observer: null},
        } as Record<string, AnimationObj>
    }
  },
});
